/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';
import type { Tier } from '../flow/flowTypes';

const baseUrl = '/merchant/v1/loyalty';

const getRewards = async (skip: number, take: number, searchString: string) => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/getlist?skip=${skip}&take=${take}`);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const editTier = async (tierId: number, model: Tier) => {
  try {
    const output = await axiosApiInstance.put(`${baseUrl}/rewardtier?id=${tierId}`, model);
    toast.success('Tier info updated.');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const createTier = async (model: Tier) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/rewardtier`, model);
    toast.success('New tier created.');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const deleteTier = async (tierId: number) => {
  try {
    const output = await axiosApiInstance.delete(`${baseUrl}/rewardtier?id=${tierId}`);
    toast.success('Tier deleted.');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const getTiers = async () => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/rewardtier`);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

export { getRewards, editTier, createTier, deleteTier, getTiers };
