/* eslint-disable react/no-unused-state, react/state-in-constructor */
// @flow
import React from 'react';

import type { TransactionRequestModel, TransactionPaginationModel } from '../../flow/flowTypes';
import {
  createTransaction,
  editTransaction,
  getTransactions,
  deleteTransaction,
} from '../../controllers/transactionController';

const { Provider, Consumer } = React.createContext<any>();

type State = {
  createMemberTransaction: Function,
  editMemberTransaction: Function,
  getMemberTransactions: Function,
  deleteMemberTransaction: Function,
  isTransactionLoading: boolean,
  transactionPaginationModel: TransactionPaginationModel,
};

type Props = {
  children: Object,
};

export default class TransactionProvider extends React.Component<Props, State> {
  state = {
    isTransactionLoading: false,
    transactionPaginationModel: null,
    createMemberTransaction: async (model: TransactionRequestModel) => {
      this.setState({ isTransactionLoading: true });
      const { transactionPaginationModel } = this.state;
      try {
        const result = await createTransaction(model);
        if (transactionPaginationModel) {
          const newModel = {
            total: transactionPaginationModel.total + 1,
            dataArray: [result, ...transactionPaginationModel.dataArray],
          };
          this.setState({ transactionPaginationModel: newModel });
        }
        this.setState({ isTransactionLoading: false });
      } catch (_e) {
        this.setState({ isTransactionLoading: false });
      }
    },
    editMemberTransaction: async (model: TransactionRequestModel, id: number) => {
      this.setState({ isTransactionLoading: true });
      const { transactionPaginationModel } = this.state;
      try {
        const result = await editTransaction(model, id);
        this.setState({ isTransactionLoading: false });
        if (transactionPaginationModel) {
          const position = transactionPaginationModel.dataArray.findIndex((t) => t.id === id);
          if (position !== -1) {
            this.setState({
              transactionPaginationModel: {
                total: transactionPaginationModel.total,
                dataArray: [
                  ...transactionPaginationModel.dataArray.slice(0, position),
                  result,
                  ...transactionPaginationModel.dataArray.slice(position + 1),
                ],
              },
            });
          }
        }
      } catch (_e) {
        this.setState({ isTransactionLoading: false });
      }
    },
    getMemberTransactions: async (
      startDate: any,
      endDate: any,
      skip: number,
      take: number,
      customerId: string,
      fullname: string,
      icNo: string,
      phoneNumber: string,
      membership: string
    ) => {
      this.setState({ isTransactionLoading: true });
      try {
        const result = await getTransactions(
          startDate,
          endDate,
          skip,
          take,
          customerId,
          fullname,
          icNo,
          phoneNumber,
          membership
        );
        this.setState({ isTransactionLoading: false });
        this.setState({ transactionPaginationModel: result });
      } catch (_e) {
        this.setState({ isTransactionLoading: false });
      }
    },
    deleteMemberTransaction: async (id: number) => {
      this.setState({ isTransactionLoading: true });
      const { transactionPaginationModel } = this.state;

      try {
        await deleteTransaction(id);
        this.setState({ isTransactionLoading: false });
        if (transactionPaginationModel) {
          const position = transactionPaginationModel.dataArray.findIndex((t) => t.id === id);
          if (position !== -1) {
            this.setState({
              transactionPaginationModel: {
                total: transactionPaginationModel.total,
                dataArray: transactionPaginationModel.dataArray.filter((e) => e.id !== id),
              },
            });
          }
        }
      } catch (_e) {
        this.setState({ isTransactionLoading: false });
      }
    },
  };

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const TransactionConsumer = (props: Props) => {
  const { children } = props;
  return <Consumer>{children}</Consumer>;
};
