/* eslint-disable react/no-unused-state, react/state-in-constructor */
// @flow
import React from 'react';
import moment from 'moment';

import type {
  CustomerPaginationModel,
  CustomerModel,
  UserRegisterModel,
  TransactionRequestModel,
  TransactionPaginationModel,
  CompanyModel,
  AddressModel,
  MemberTierRequestModel,
  MembershipModel,
  Tier,
} from '../../flow/flowTypes';
import { getCustomers, getMembership } from '../../controllers/memberController';
import {
  deleteUser,
  activateUser,
  editUser,
  addUser,
  getCustomerDetail,
  editCompany,
  editAddress,
  addAddress,
} from '../../controllers/authController';
import {
  createTransaction,
  getTransactions,
  promoteUser,
} from '../../controllers/transactionController';
import { TransactionActionType, CustomerSortType } from '../../utils/enum';

const { Provider, Consumer } = React.createContext<any>();

type State = {
  getCustomerList: Function,
  getCustomerById: Function,
  deleteMember: Function,
  activateMember: Function,
  editMember: Function,
  addMember: Function,
  isCustomerLoading: boolean,
  customerModel: CustomerPaginationModel,
  customer: CustomerModel,
  hasCreated: boolean,
  createMemberPoint: Function,
  isCustomerTransactionLoading: boolean,
  memberTransactionPaginationModel: ?TransactionPaginationModel,
  getMembershipInfo: Function,
  membershipModel: ?MembershipModel,
};

type Props = {
  children: Object,
};

export default class MemberProvider extends React.Component<Props, State> {
  state = {
    isCustomerTransactionLoading: false,
    isCustomerLoading: false,
    customerModel: null,
    customer: null,
    hasCreated: false,
    memberTransactionPaginationModel: null,
    membershipModel: null,
    getCustomerList: async (
      skip: number,
      take: number,
      searchString: string,
      sortType: CustomerSortType,
      customerId: string
    ) => {
      this.setState({ isCustomerLoading: true });
      try {
        const result = await getCustomers(skip, take, searchString, sortType, customerId);
        this.setState({ customerModel: result });

        this.setState({ isCustomerLoading: false });
      } catch (_e) {
        this.setState({ isCustomerLoading: false });
      }
    },
    getCustomerById: async (customerId: string) => {
      this.setState({ isCustomerLoading: true });
      try {
        const result = await getCustomerDetail(customerId);
        this.setState({ customer: result });

        this.setState({ isCustomerLoading: false });
      } catch (_e) {
        this.setState({ isCustomerLoading: false });
      }
    },
    deleteMember: async (customerId: string) => {
      this.setState({ isCustomerLoading: true });
      const { customerModel, customer } = this.state;
      try {
        await deleteUser(customerId);
        if (customerModel) {
          const updatedCustomerModel = {
            total: customerModel.total - 1,
            dataArray: customerModel.dataArray.filter((e) => e.id !== customerId),
          };
          this.setState({ customerModel: updatedCustomerModel });
        }

        if (customer) {
          this.setState({ customer: { ...customer, isDeleted: true } });
        }

        this.setState({ isCustomerLoading: false });
      } catch (_e) {
        this.setState({ isCustomerLoading: false });
      }
    },
    activateMember: async (customerId: string, isActive: boolean) => {
      this.setState({ isCustomerLoading: true });
      const { customerModel, customer } = this.state;
      try {
        await activateUser(customerId, isActive);
        if (customerModel) {
          const position = customerModel.dataArray.findIndex((e) => e.id === customerId);
          if (position !== -1) {
            const updatedCustomer = customerModel.dataArray[position];
            updatedCustomer.isActive = isActive;
            const updatedCustomerModel = {
              ...customerModel,
              dataArray: [
                ...customerModel.dataArray.slice(0, position),
                updatedCustomer,
                ...customerModel.dataArray.slice(position + 1),
              ],
            };
            this.setState({ customerModel: updatedCustomerModel });
          }
        }

        if (customer) {
          this.setState({ customer: { ...customer, isActive } });
        }
        this.setState({ isCustomerLoading: false });
      } catch (_e) {
        this.setState({ isCustomerLoading: false });
      }
    },
    editMember: async (customerId: string, model: UserRegisterModel) => {
      this.setState({ isCustomerLoading: true });
      const { customerModel, customer } = this.state;
      try {
        const result = await editUser(customerId, model);
        if (customerModel) {
          const position = customerModel.dataArray.findIndex((e) => e.id === customerId);
          if (position !== -1) {
            const existingData = customerModel.dataArray[position];

            const updatedCustomerModel = {
              ...customerModel,
              dataArray: [
                ...customerModel.dataArray.slice(0, position),
                { ...result, points: [...existingData.points] },
                ...customerModel.dataArray.slice(position + 1),
              ],
            };

            this.setState({ customerModel: updatedCustomerModel });
          }
        }
        if (customer) {
          const updatedCustomer = result;
          updatedCustomer.addresses = customer.addresses;
          this.setState({ customer: updatedCustomer });
        }

        this.setState({ isCustomerLoading: false });
      } catch (_e) {
        this.setState({ isCustomerLoading: false });
      }
    },
    addMember: async (model: UserRegisterModel) => {
      this.setState({ isCustomerLoading: true, hasCreated: false });
      const { customerModel } = this.state;
      try {
        const result = await addUser(model);
        if (customerModel) {
          const newCustomerModel = {
            total: customerModel.total + 1,
            dataArray: [result, ...customerModel.dataArray],
          };
          this.setState({ customerModel: newCustomerModel });
        }

        this.setState({ isCustomerLoading: false, hasCreated: true });
      } catch (_e) {
        this.setState({ isCustomerLoading: false, hasCreated: false });
      }
    },
    createMemberPoint: async (model: TransactionRequestModel) => {
      this.setState({ isCustomerTransactionLoading: true });
      const { memberTransactionPaginationModel, membershipModel } = this.state;
      try {
        const result = await createTransaction(model);
        if (membershipModel && membershipModel.myPoints) {
          const selectedPointPos = membershipModel.myPoints.findIndex(
            (e) => e.reward.id === model.rewardId
          );
          if (selectedPointPos !== -1) {
            // if existing based on reward id
            const selectedPoint = membershipModel.myPoints[selectedPointPos];
            let pointUpdated = selectedPoint.point;
            if (model.transactionType === TransactionActionType.Add) {
              pointUpdated += Number(model.point);
            } else {
              pointUpdated -= Number(model.point);
            }
            const updatedPoint = {
              ...selectedPoint,
              point: pointUpdated < 0 ? 0 : pointUpdated,
              lastUpdatedAt: moment(),
            };
            const updatedMembershipModel = membershipModel;
            updatedMembershipModel.myPoints = [
              ...membershipModel.myPoints.slice(0, selectedPointPos),
              updatedPoint,
              ...membershipModel.myPoints.slice(selectedPointPos + 1),
            ];
            this.setState({ membershipModel: updatedMembershipModel });
          } else {
            // if different reward id or new entry
            const updatedPoint = {
              point: TransactionActionType.Minus ? 0 : Number(model.point),
              lastUpdatedAt: moment(),
              reward: { id: model.rewardId },
            };
            const updatedMembershipModel = membershipModel;
            updatedMembershipModel.myPoints.push(updatedPoint);
            this.setState({ membershipModel: updatedMembershipModel });
          }
        } else {
          const newMembershipModel = {
            myPoints: [{ reward: { id: model.rewardId }, point: model.point }],
          };
          this.setState({ membershipModel: newMembershipModel });
        }

        if (memberTransactionPaginationModel) {
          const newModel = {
            total: memberTransactionPaginationModel.total + 1,
            dataArray: [result, ...memberTransactionPaginationModel.dataArray],
          };
          this.setState({ memberTransactionPaginationModel: newModel });
        }
        this.setState({ isCustomerTransactionLoading: false });
      } catch (_e) {
        this.setState({ isCustomerTransactionLoading: false });
      }
    },
    getUserTransactions: async (
      startDate: any,
      endDate: any,
      skip: number,
      take: number,
      searchValue: string,
      customerId: string
    ) => {
      this.setState({ isTransactionLoading: true });
      try {
        const result = await getTransactions(startDate, endDate, skip, take, customerId, '', '', '', '');
        this.setState({ isCustomerTransactionLoading: false });
        this.setState({ memberTransactionPaginationModel: result });
      } catch (_e) {
        this.setState({ isCustomerTransactionLoading: false });
      }
    },
    editCustomerCompany: async (companyId: number, customerId: string, model: CompanyModel) => {
      const { customer, customerModel } = this.state;
      this.setState({ isTransactionLoading: true });
      try {
        const result = await editCompany(companyId, customerId, model);
        if (customer && customer.id === customerId) {
          const updatedCustomer = customer;
          updatedCustomer.companyName = model.companyName;
          updatedCustomer.companyRegistrationNo = model.companyRegistrationNo;
          const selectedPosition = updatedCustomer.companies
            ? updatedCustomer.companies.findIndex((e) => e.id === companyId)
            : -1;
          if (selectedPosition !== -1) {
            updatedCustomer.companies = [
              ...updatedCustomer.companies.slice(0, selectedPosition),
              result,
              ...updatedCustomer.companies.slice(selectedPosition + 1),
            ];
          } else if (updatedCustomer.companies) {
            updatedCustomer.companies.push(result);
          } else {
            updatedCustomer.companies = [result];
          }
          this.setState({ customer: updatedCustomer });
        }

        if (customerModel) {
          const selectedCustomerPosition = customerModel.dataArray.findIndex(
            (e) => e.id === customerId
          );
          if (selectedCustomerPosition !== -1) {
            const selectedCustomer = customerModel.dataArray[selectedCustomerPosition];
            const selectedPosition = selectedCustomer.companies
              ? selectedCustomer.companies.findIndex((e) => e.id === companyId)
              : -1;
            if (selectedPosition !== -1) {
              selectedCustomer.companies = [
                ...selectedCustomer.companies.slice(0, selectedPosition),
                result,
                ...selectedCustomer.companies.slice(selectedPosition + 1),
              ];
            } else if (selectedCustomer.companies) {
              selectedCustomer.companies.push(result);
            } else {
              selectedCustomer.companies = [result];
            }
            const newModel = {
              ...customerModel,
              dataArray: [
                ...customerModel.dataArray.slice(0, selectedCustomerPosition),
                selectedCustomer,
                ...customerModel.dataArray.slice(selectedCustomerPosition + 1),
              ],
            };
            this.setState({ customerModel: newModel });
          }
        }

        this.setState({ isCustomerTransactionLoading: false });
      } catch (_e) {
        this.setState({ isCustomerTransactionLoading: false });
      }
    },
    editCustomerAddress: async (addressId: number, customerId: string, model: AddressModel) => {
      const { customer, customerModel } = this.state;
      this.setState({ isTransactionLoading: true });
      try {
        const result = await editAddress(addressId, customerId, model);
        if (customer && customer.id === customerId) {
          const updatedCustomer = customer;
          const selectedPosition = updatedCustomer.addresses
            ? updatedCustomer.addresses.findIndex((e) => e.id === addressId)
            : -1;
          if (selectedPosition !== -1) {
            updatedCustomer.addresses = [
              ...updatedCustomer.addresses.slice(0, selectedPosition),
              result,
              ...updatedCustomer.addresses.slice(selectedPosition + 1),
            ];
          } else if (updatedCustomer.addresses) {
            updatedCustomer.addresses.push(result);
          } else {
            updatedCustomer.addresses = [result];
          }
          this.setState({ customer: updatedCustomer });
        }

        if (customerModel) {
          const selectedCustomerPosition = customerModel.dataArray.findIndex(
            (e) => e.id === customerId
          );
          if (selectedCustomerPosition !== -1) {
            const selectedCustomer = customerModel.dataArray[selectedCustomerPosition];
            const selectedPosition = selectedCustomer.addresses
              ? selectedCustomer.addresses.findIndex((e) => e.id === addressId)
              : -1;
            if (selectedPosition !== -1) {
              selectedCustomer.addresses = [
                ...selectedCustomer.addresses.slice(0, selectedPosition),
                result,
                ...selectedCustomer.addresses.slice(selectedPosition + 1),
              ];
            } else if (selectedCustomer.addresses) {
              selectedCustomer.addresses.push(result);
            } else {
              selectedCustomer.addresses = [result];
            }
            const newModel = {
              ...customerModel,
              dataArray: [
                ...customerModel.dataArray.slice(0, selectedCustomerPosition),
                selectedCustomer,
                ...customerModel.dataArray.slice(selectedCustomerPosition + 1),
              ],
            };
            this.setState({ customerModel: newModel });
          }
        }
        this.setState({ isCustomerTransactionLoading: false });
      } catch (_e) {
        this.setState({ isCustomerTransactionLoading: false });
      }
    },
    addCustomerAddress: async (customerId: string, model: AddressModel) => {
      const { customer, customerModel } = this.state;
      this.setState({ isTransactionLoading: true });
      try {
        const result = await addAddress(model, customerId);
        if (customer && customer.id === customerId) {
          const updatedCustomer = customer;
          if (updatedCustomer.addresses) {
            updatedCustomer.addresses.push(result);
          } else {
            updatedCustomer.addresses = [result];
          }
          this.setState({ customer: updatedCustomer });
        }

        if (customerModel) {
          const selectedCustomerPosition = customerModel.dataArray.findIndex(
            (e) => e.id === customerId
          );
          if (selectedCustomerPosition !== -1) {
            const selectedCustomer = customerModel.dataArray[selectedCustomerPosition];
            if (selectedCustomer.addresses) {
              selectedCustomer.addresses.push(result);
            } else {
              selectedCustomer.addresses = [result];
            }
            const newModel = {
              ...customerModel,
              dataArray: [
                ...customerModel.dataArray.slice(0, selectedCustomerPosition),
                selectedCustomer,
                ...customerModel.dataArray.slice(selectedCustomerPosition + 1),
              ],
            };
            this.setState({ customerModel: newModel });
          }
        }
        this.setState({ isCustomerTransactionLoading: false });
      } catch (_e) {
        this.setState({ isCustomerTransactionLoading: false });
      }
    },
    upgradeUser: async (model: MemberTierRequestModel, selectedTier: Tier) => {
      this.setState({ isCustomerTransactionLoading: true });
      const { memberTransactionPaginationModel, membershipModel } = this.state;
      try {
        const result = await promoteUser(model);
        if (memberTransactionPaginationModel) {
          const newModel = {
            total: memberTransactionPaginationModel.total + 1,
            dataArray: [result, ...memberTransactionPaginationModel.dataArray],
          };
          this.setState({ memberTransactionPaginationModel: newModel });
        }

        if (membershipModel) {
          const newMembershipModel = membershipModel;
          newMembershipModel.myTier = { rewardTier: selectedTier };
          this.setState({ membershipModel: newMembershipModel });
        }
        this.setState({ isCustomerTransactionLoading: false });
      } catch (_e) {
        this.setState({ isCustomerTransactionLoading: false });
      }
    },
    getMembershipInfo: async (customerId: string) => {
      this.setState({ isCustomerTransactionLoading: true });
      try {
        const result = await getMembership(customerId);

        this.setState({ isCustomerTransactionLoading: false, membershipModel: result });
      } catch (_e) {
        this.setState({ isCustomerTransactionLoading: false });
      }
    },
  };

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const MemberConsumer = (props: Props) => {
  const { children } = props;
  return <Consumer>{children}</Consumer>;
};
