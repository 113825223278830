/* eslint-disable react/no-unused-state, react/state-in-constructor */
// @flow
import React from 'react';

import LocalStorageService from '../../services/localstorageservice';
import {
  generateTransactionReport,
  generateMemberListReport,
  generateMemberDetailReport
} from '../../controllers/reportController';
import type { Report} from '../../flow/flowTypes';

const localStorageService = LocalStorageService.getService();
const { Provider, Consumer } = React.createContext<any>();

type State = {
  generateReport: Function,
  isReportLoading: boolean,
  report: Report,
  reportMember: Report,
  reportMemberDetail: Report,
  generateMemberDetailReport: Function
};

type Props = {
  children: Object,
};

export default class ReportProvider extends React.Component<Props, State> {
  state = {
    isLoading: false,
    rewards: localStorageService.getRewardList(),
    tiers: localStorageService.getTierList(),
    generateReport: async (startDate: string, endDate: string) => {
      this.setState({ isReportLoading: true });
      try {
        const result = await generateTransactionReport(startDate, endDate);
        this.setState({ isReportLoading: false, report: result });
      } catch (_e) {
        this.setState({ isReportLoading: false });
      }
    },
    generateMemberReport: async (searchString: string) => {
      this.setState({ isReportLoading: true });
      try {
        const result = await generateMemberListReport(searchString);
        this.setState({ isReportLoading: false, reportMember: result });
      } catch (_e) {
        this.setState({ isReportLoading: false });
      }
    },
    generateMemberDetailReport: async (searchString: string, startDate: string, endDate: string) => {
      this.setState({ isReportLoading: true });
      try {
        const result = await generateMemberDetailReport(searchString, startDate, endDate);
        this.setState({ isReportLoading: false, reportMemberDetail: result });
      } catch (_e) {
        this.setState({ isReportLoading: false });
      }
    }
  };

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const ReportConsumer = (props: Props) => {
  const { children } = props;
  return <Consumer>{children}</Consumer>;
};
