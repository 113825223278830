/* eslint-disable react/no-unused-state, react/state-in-constructor */
// @flow
import React from 'react';

import { getNotifications, getNotificationById, readAll } from '../../controllers/notificationController';
import type {
  Notification
} from '../../flow/flowTypes';

const { Provider, Consumer } = React.createContext<any>();

type State = {
  getNotificationList: Function,
  getNotificationDetail: Function,
  readAllNotification: Function,
  isNotificationLoading: boolean,
  notification: Notification,
  notifications: Array<Notification>
};

type Props = {
  children: Object,
};

export default class NotificationProvider extends React.Component<Props, State> {
  state = {
    isNotificationLoading: false,
    notification: null,
    notificationPaginationModel: null,
    getNotificationList: async (skip: number, take: number) => {
      this.setState({ isNotificationLoading: true });
      try {
        const result = await getNotifications(skip, take);
        this.setState({ isNotificationLoading: false, notificationPaginationModel: result });
      } catch (_e) {
        this.setState({ isNotificationLoading: false });
      }
    },
    getNotificationDetail: async (notificationId: number) => {
      this.setState({ isNotificationLoading: true });
      try {
        const result = await getNotificationById(notificationId);
        this.setState({ isNotificationLoading: false, notification: result });
      } catch (_e) {
        this.setState({ isNotificationLoading: false });
      }
    },
    readAllNotification: async () => {
      try {
        readAll();
      } catch (e) {
        /* eslint-disable no-console */
        console.log('e', e);
      }
    },
  };

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const NotificationConsumer = (props: Props) => {
  const { children } = props;
  return <Consumer>{children}</Consumer>;
};
