/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';
import { ReportType } from '../utils/enum';

const baseUrl = '/merchant/v1/report';

const generateTransactionReport = async (
  startDate: string,
  endDate: string,
) => {
  const reportData = {
    StartDate: startDate,
    EndDate: endDate,
    Type: ReportType.MemberTransaction
  }
  try {
    const output = await axiosApiInstance.post(
      `${baseUrl}/report`,
      reportData
    );
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const generateMemberListReport = async (
  searchString: string,
) => {
  const reportData = {
    SearchString: searchString,
    Type: ReportType.MemberList
  }
  try {
    const output = await axiosApiInstance.post(
      `${baseUrl}/memberreport`,
      reportData
    );
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const generateMemberDetailReport = async (
  searchString: string,
  startDate: string,
  endDate: string,
) => {
  const reportData = {
    SearchValue: searchString,
    Type: ReportType.MemberDetailTransaction,
    StartDate: startDate,
    EndDate: endDate,
    CustomerId: searchString
  }
  try {
    const output = await axiosApiInstance.post(
      `${baseUrl}/memberdetailreport`,
      reportData
    );
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};


export { generateTransactionReport, generateMemberListReport, generateMemberDetailReport };
