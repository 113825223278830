/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';
import type { TransactionRequestModel, MemberTierRequestModel } from '../flow/flowTypes';

const baseUrl = '/merchant/v1/membershiptransaction';

const createTransaction = async (transaction: TransactionRequestModel) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/MemberTransaction`, transaction);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const editTransaction = async (transaction: TransactionRequestModel, id: number) => {
  try {
    const output = await axiosApiInstance.put(`${baseUrl}/MemberTransaction?id=${id}`, transaction);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const getTransactions = async (
  startDate: any,
  endDate: any,
  skip: number,
  take: number,
  customerId: string,
  fullname: string,
  icNo: string,
  phoneNumber: string,
  membership: string
) => {
  try {
    const output = await axiosApiInstance.get(
      `${baseUrl}/MemberTransactionList?startDate=${startDate}&endDate=${endDate}&skip=${skip}&take=${take}&customerId=${customerId}&fullname=${fullname}&icNo=${icNo}&phoneNumber=${phoneNumber}&membership=${membership} `
    );
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const deleteTransaction = async (id: number) => {
  try {
    const output = await axiosApiInstance.delete(`${baseUrl}/MemberTransaction?id=${id}`);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const promoteUser = async (model: MemberTierRequestModel) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/Promote`, model);
    toast.success('Customer is promoted');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

export { createTransaction, editTransaction, getTransactions, deleteTransaction, promoteUser };
