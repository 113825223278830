// @flow
import { SHOP_NAME } from '../utils/constants';
import themes from '../utils/themeconstants';
import type { AuthModel, Reward } from '../flow/flowTypes';

const LocalStorageService = (function () {
  let service;

  function getService() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  function setUser(authInfo: AuthModel) {
    if (authInfo) {
      localStorage.setItem('auth_admin_user', JSON.stringify(authInfo));
    } else {
      localStorage.setItem('auth_admin_user', JSON.stringify(''));
    }
  }

  function getUser() {
    if (localStorage.getItem('auth_admin_user')) {
      return JSON.parse(localStorage.getItem('auth_admin_user'));
    }
    return null;
  }

  function setToken(authInfo: AuthModel) {
    localStorage.setItem('admin_access_token', authInfo.auth_token);
    localStorage.setItem('admin_refresh_token', authInfo.refresh_token);
  }

  function setAccessToken(accessToken: string) {
    localStorage.setItem('admin_access_token', accessToken);
  }

  function getAccessToken() {
    return localStorage.getItem('admin_access_token');
  }

  function setRefreshToken(refreshToken: string) {
    localStorage.setItem('admin_refresh_token', refreshToken);
  }

  function getRefreshToken() {
    return localStorage.getItem('admin_refresh_token');
  }

  function clearData() {
    localStorage.removeItem('auth_admin_user');
    localStorage.removeItem('admin_access_token');
    localStorage.removeItem('admin_refresh_token');
    localStorage.removeItem('rewards');
  }

  function setShopName(shopName: string) {
    localStorage.setItem(SHOP_NAME, shopName);
  }

  function getShopName() {
    return localStorage.getItem(SHOP_NAME);
  }

  function setDeviceId(deviceId: string) {
    localStorage.setItem('device_id', deviceId);
  }

  function getDeviceId() {
    return localStorage.getItem('device_id');
  }

  function setRegistrationId(deviceId: string) {
    localStorage.setItem('registration_id', deviceId);
  }

  function getRegistrationId() {
    return localStorage.getItem('registration_id');
  }

  function setTheme(theme: Object) {
    localStorage.setItem('theme', JSON.stringify(theme));
  }

  function getTheme() {
    return localStorage.getItem('theme') || JSON.stringify(themes.default);
  }

  function setRewardList(rewards: Array<Reward>) {
    if (rewards) {
      localStorage.setItem('rewards', JSON.stringify(rewards));
    }
  }

  function getRewardList() {
    if (localStorage.getItem('rewards')) {
      return JSON.parse(localStorage.getItem('rewards'));
    }
    return [];
  }

  function setTierList(rewards: Array<Tier>) {
    if (rewards) {
      localStorage.setItem('tiers', JSON.stringify(rewards));
    }
  }

  function getTierList() {
    if (localStorage.getItem('tiers')) {
      return JSON.parse(localStorage.getItem('tiers'));
    }
    return [];
  }

  return {
    getService,
    setUser,
    getUser,
    setToken,
    setAccessToken,
    getAccessToken,
    setRefreshToken,
    getRefreshToken,
    clearData,
    setShopName,
    getShopName,
    setDeviceId,
    getDeviceId,
    setRegistrationId,
    getRegistrationId,
    setTheme,
    getTheme,
    setRewardList,
    getRewardList,
    setTierList,
    getTierList,
  };
})();

export default LocalStorageService;
