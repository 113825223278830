/* eslint-disable */
import React from 'react';

import RewardProvider, { RewardConsumer } from './RewardContext/reward';
import MemberProvider, { MemberConsumer } from './MemberContext/member';
import TransactionProvider, { TransactionConsumer } from './TransactionContext/transaction';
import ReportProvider, { ReportConsumer } from './ReportContext/report';
import NotificationProvider, { NotificationConsumer } from './NotificationContext/notification';

const CombinedContextProvider = ({ children }) => (
  <RewardProvider>
    <MemberProvider>
      <TransactionProvider>
        <ReportProvider>
          <NotificationProvider>
            {children}
          </NotificationProvider>
        </ReportProvider>
      </TransactionProvider>
    </MemberProvider>
  </RewardProvider>
);

const CombineContextConsumer = ({ children, props }) => {
  return (
    <RewardConsumer>
      {(rewardContext) => {
        return (
          <MemberConsumer>
            {(memberContext) => {
              return (
                <TransactionConsumer>
                  {(transactionContext) => {
                    return (<ReportConsumer>
                      {(reportContext) => {
                        return (
                          <NotificationConsumer>
                            {(notificationContext) => {
                              return children({
                                ...rewardContext,
                                ...memberContext,
                                ...transactionContext,
                                ...reportContext,
                                ...notificationContext,
                                ...props,
                              });
                            }
                            }
                          </NotificationConsumer>
                        )
                      }}
                    </ReportConsumer>)
                  }}
                </TransactionConsumer>
              );
            }}
          </MemberConsumer>
        );
      }}
    </RewardConsumer>
  );
};

export { CombinedContextProvider, CombineContextConsumer };
