/* eslint-disable react/no-unused-state, react/state-in-constructor */
// @flow
import React from 'react';

import LocalStorageService from '../../services/localstorageservice';
import type { Reward, Tier } from '../../flow/flowTypes';
import {
  getRewards,
  editTier,
  createTier,
  deleteTier,
  getTiers,
} from '../../controllers/rewardController';

const localStorageService = LocalStorageService.getService();
const { Provider, Consumer } = React.createContext<any>();

type State = {
  getRewardList: Function,
  isLoading: boolean,
  rewards: Array<Reward>,
  rewardSelected: Reward,
  getTierList: Function,
  tiers: Array<Tier>,
};

type Props = {
  children: Object,
};

export default class RewardProvider extends React.Component<Props, State> {
  state = {
    isLoading: false,
    rewards: localStorageService.getRewardList(),
    tiers: localStorageService.getTierList(),
    getRewardList: async (skip: number, take: number, searchString: string) => {
      this.setState({ isLoading: true });
      const { rewards } = this.state;
      try {
        const result = await getRewards(skip, take, searchString);
        if (skip === 0) {
          localStorageService.setRewardList(result);
          this.setState({
            rewards: result,
            rewardSelected: result && result.length ? result[0] : null,
          });
        } else {
          this.setState({ rewards: [...rewards, result] });
        }
        this.setState({ isLoading: false });
      } catch (_e) {
        this.setState({ isLoading: false });
      }
    },
    updateTier: async (tierId: number, model: Tier) => {
      this.setState({ isLoading: true });
      const { tiers } = this.state;
      try {
        await editTier(tierId, model);
        const selectedPos = tiers.findIndex((e) => e.id === tierId);
        if (selectedPos !== -1) {
          const newTiers = [...tiers.slice(0, selectedPos), model, ...tiers.slice(selectedPos + 1)];
          this.setState({
            tiers: newTiers,
          });
        }
        this.setState({ isLoading: false });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    },
    addTier: async (model: Tier) => {
      this.setState({ isLoading: true });
      const { tiers } = this.state;
      try {
        const result = await createTier(model);
        const selectedPos = tiers.findIndex((e) => e.id === result.id);
        if (selectedPos === -1) {
          this.setState({
            tiers: [result, ...tiers],
          });
        }
        this.setState({ isLoading: false });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    },
    removeTier: async (tierId: number) => {
      this.setState({ isLoading: true });
      const { tiers } = this.state;
      try {
        await deleteTier(tierId);
        const newList = tiers.filter((e) => e.id !== tierId);
        this.setState({ tiers: newList, isLoading: false });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    },
    getTierList: async () => {
      this.setState({ isLoading: true });
      try {
        const result = await getTiers();
        localStorageService.setTierList(result);
        this.setState({ tiers: result });
        this.setState({ isLoading: false });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    },
  };

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const RewardConsumer = (props: Props) => {
  const { children } = props;
  return <Consumer>{children}</Consumer>;
};
