/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';
import LocalStorageService from '../services/localstorageservice';
import { CustomerSortType } from '../utils/enum';

const baseUrl = '/merchant/v1/customer';
const localStorageService = LocalStorageService.getService();

const getCustomers = async (
  skip: number,
  take: number,
  searchString: string,
  sortType: CustomerSortType,
  customerId: string
) => {
  try {
    const output = await axiosApiInstance.get(
      `${baseUrl}/CustomerList?skip=${skip}&take=${take}&searchValue=${searchString}&customerId=${customerId}&sortType=${sortType}`
    );
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const getMembership = async (userId: string) => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/MembershipInfo?customerId=${userId}`);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

export { getCustomers, getMembership };
