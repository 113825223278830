/* eslint-disable import/prefer-default-export */
export const TransactionActionType = {
  Add: 1,
  Minus: 2,
  Promote: 3,
};

export const RewardType = {
  Amount: 1,
  Qty: 2,
};

export const Gender = {
  Female: 1,
  Male: 2,
};

export const AddressType = {
  Company: 1,
  Individual: 2,
};

export const AccountType = {
  Company: 1,
  Individual: 2,
};

export const CustomerSortType = {
  NameAsc: 0,
  NameDesc: 1,
  PointAsc: 2,
  PointDesc: 3,
  LastPurchaseDateAsc: 4,
  LastPurchaseDateDesc: 5,
  TierAsc: 6,
  TierDesc: 7,
};

export const ReportType = {
  MemberTransaction: 0,
  MemberList: 1,
  MemberDetailTransaction: 2
}

export const NotificationType = {
  Report: 0
}
