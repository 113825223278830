const themes = {
  default: {
    '--button-background-color': '#af8e56',
    '--positive-button-text-color': '#fff',
    '--bordered-button-text-color': '#b69764',
    '--bordered-button-border-color': '#b69764',
    '--loading-color': '#af8e56',
    '--header-nav-background': '#24293a',
    '--signout-text-color': '#fff',
    '--member-outer-container-gradient-front': '#FFF3E0',
    '--member-outer-container-gradient-back': '#D9D9D9',
    '--tier-outer-container-gradient-front': '#AF8E56',
    '--tier-outer-container-gradient-back': '#CE993F ',
    '--profile-back-text': '#af8e56',
    '--profile-save-text': '#fff',
  },
  surfacearchi: {
    '--button-background-color': '#af8e56',
    '--positive-button-text-color': '#fff',
    '--bordered-button-text-color': '#b69764',
    '--bordered-button-border-color': '#b69764',
    '--loading-color': '#af8e56',
    '--signout-text-color': '#fff',
    '--member-outer-container-gradient-front': '#FFF3E0',
    '--member-outer-container-gradient-back': '#D9D9D9',
    '--tier-outer-container-gradient-front': '#AF8E56',
    '--tier-outer-container-gradient-back': '#CE993F ',
    '--profile-back-text': '#af8e56',
    '--profile-save-text': '#fff',
    '--sidebar-background': '#24293a',
    '--sidebar-inactive-text': '#fff',
    '--sidebar-hover-text': '#cfa359',
    '--sidebar-active-text': '#af8e56',
    '--header-nav-background': '#fff',
    '--header-nav-text': '#5C6586',
    '--header-nave-name-text': '#000',
    '--header-nav-profile-container': '#AF8E56',
    '--header-nav-signout-text': '#AF8E56',
    '--header-nav-signout-button': '#f7f4ef',
    '--breadcrumb-first-text': '#1587E2',
    '--breadcrumb-second-text': '#252A3B',
    '--point-history-bottom-underline': '#af8e56',
  },
};

export default themes;
