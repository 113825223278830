/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';
import LocalStorageService from '../services/localstorageservice';
import type {
  LoginRequestModel,
  DeviceModel,
  UserRegisterModel,
  ChangePasswordModel,
} from '../flow/flowTypes';

const baseUrl = '/merchant/v1/account';
const localStorageService = LocalStorageService.getService();

const login = async (loginModel: LoginRequestModel) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/login`, loginModel);
    localStorageService.setUser(output);
    localStorageService.setToken(output);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const registerDevice = (deviceModel: DeviceModel) => {
  axiosApiInstance.post(`${baseUrl}/registerdevice`, deviceModel);
};

const logout = async () => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/logout`);
    localStorageService.clearData();
    return output;
  } catch (e) {
    localStorageService.clearData();
    throw e;
  }
};

const refreshToken = () => {
  try {
    return axiosApiInstance
      .post(`${baseUrl}/refresh`)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        let errorObject = {
          errorCode: 500,
          errorMessage: 'Server error. Please try again later.',
        };
        if (e.response && e.response.data) {
          errorObject = {
            errorCode: e.response.data.ErrorCode,
            errorMessage: e.response.data.ErrorMessage,
          };
        }
        throw errorObject;
      });
  } catch (e) {
    localStorageService.clearData();
    throw e;
  }
};

const deleteUser = async (userId: string) => {
  try {
    const output = await axiosApiInstance.delete(`${baseUrl}/deletecustomer?customerId=${userId}`);
    toast.success('User has been deleted');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const activateUser = async (userId: string, isActive: boolean) => {
  try {
    const output = await axiosApiInstance.put(
      `${baseUrl}/activatecustomer?customerId=${userId}&isActive=${isActive}`
    );
    toast.success('Status updated');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const editUser = async (userId: string, model: UserRegisterModel) => {
  try {
    const output = await axiosApiInstance.put(
      `${baseUrl}/editcustomer?customerId=${userId}`,
      model
    );
    toast.success('User has been updated');

    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const addUser = async (model: UserRegisterModel) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/createcustomer`, model);
    toast.success('Customer created');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const getCustomerDetail = async (customerId: string) => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/Customer?customerId=${customerId}`);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const editCompany = async (companyId: number, customerId: string, model: CompanyModel) => {
  try {
    const output = await axiosApiInstance.put(
      `${baseUrl}/EditCompany?companyId=${companyId}&customerId=${customerId}`,
      model
    );
    toast.success('Company info updated.');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const editAddress = async (addressId: number, customerId: string, model: AddressModel) => {
  try {
    const output = await axiosApiInstance.put(
      `${baseUrl}/EditAddress?addressId=${addressId}&customerId=${customerId}`,
      model
    );
    toast.success('Address updated.');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const addAddress = async (model: AddressModel, customerId: string) => {
  try {
    const output = await axiosApiInstance.post(
      `${baseUrl}/AddAddress?customerId=${customerId}`,
      model
    );
    toast.success('Address added');
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const changePassword = async (model: ChangePasswordModel) => {
  try {
    const output = await axiosApiInstance.post(`${baseUrl}/ChangePassword`, model);
    toast.success('Password has been changed.');
    return output;
  } catch (e) {
    if (e.response.data.errors) {
      const keys = Object.keys(e.response.data.errors);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const message = e.response.data.errors[key][0];
        toast.error(message);
        break;
      }
    } else {
      toast.error(e.response.data.errorMessage);
    }
    throw e;
  }
};

const getPostcodes = async (postcode: string) => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/postcodes?postcode=${postcode}`);

    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

export {
  login,
  registerDevice,
  logout,
  refreshToken,
  deleteUser,
  activateUser,
  editUser,
  addUser,
  getCustomerDetail,
  editCompany,
  editAddress,
  addAddress,
  changePassword,
  getPostcodes,
};
