/* eslint-disable react/no-unused-state */
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, HashRouter } from 'react-router-dom';

import { AuthContext } from './contexts/AuthenticationContext';
import LocalStorageService from './services/localstorageservice';
import { CombinedContextProvider } from './contexts/CombinationContext';

const App = lazy(() => import('./containers/App'));

const localStorageService = LocalStorageService.getService();

class RootRouter extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      authUser: localStorageService.getUser(),
      updateAuthUser: this.updateAuthUser,
    };
  }

  updateAuthUser = (authUser) => {
    this.setState({ authUser });
    if (authUser) {
      localStorageService.setUser(authUser);
    } else {
      localStorageService.setUser(null);
    }
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        <CombinedContextProvider>
          <HashRouter>
            <Suspense
              fallback={
                <div className="preloader">
                  <div className="loader">
                    <div className="css-loader" />
                  </div>
                </div>
              }
            >
              <Route component={App} />
            </Suspense>
          </HashRouter>
        </CombinedContextProvider>
      </AuthContext.Provider>
    );
  }
}

export default RootRouter;
