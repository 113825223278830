/* eslint-disable react/jsx-props-no-spreading, react/no-unused-state */
// @flow
import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import AuthContext from './context';
import LocalStorageService from '../../services/localstorageservice';

type State = {
  authUser: Object,
};
type Props = {
  history: any,
  location: any,
};

const localStorageService = LocalStorageService.getService();

const withAuthentication = (Component: any) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class WithAuthentication extends React.Component<Props, State> {
    constructor(props) {
      super(props);
      this.state = {
        authUser: localStorageService.getUser(),
        authContext: null,
      };
    }

    componentDidMount() {
      const { authUser } = this.state;
      const { history, location } = this.props;
      if (!authUser) {
        if (
          !location.pathname.includes('login') &&
          !location.pathname.includes('recover') &&
          !location.pathname.includes('signup')
        ) {
          if (location.pathname.split('/').length >= 2) {
            history.replace(`/admin/${localStorageService.getShopName()}/login`, null);
          } else {
            history.push(`${location.pathname}/login`);
          }
        }
      }
    }

    updateAuthUser = (authUser) => {
      this.setState({ authUser });
    };

    render() {
      return (
        <AuthContext.Consumer>
          {(authContext) => {
            return <Component {...this.props} authContext={authContext} />;
          }}
        </AuthContext.Consumer>
      );
    }
  }

  return compose(withRouter)(WithAuthentication);
};

export default withAuthentication;
