/* eslint-disable import/prefer-default-export, no-console */
const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((_registration) => {
        // messaging.useServiceWorker(registration);
        console.log('Registration successful')
      })
      .catch((err) => {
        console.log('Service worker registration failed, error:', err)
      })
  }
}

export { registerServiceWorker }
