const SHOP_NAME = 'surfacearchi';
// const BASE_URL = 'https://crm-apistaging.azurewebsites.net/api';
const BASE_URL = 'https://crm-apiprod.azurewebsites.net/api';
// const BASE_URL = 'http://localhost:50382/api';
const GUI_VERSION = '1.0.0';

const STATE_LIST = [
  { value: 'Selangor', label: 'Selangor' },
  { value: 'Kuala Lumpur', label: 'Kuala Lumpur' },
  { value: 'Johor', label: 'Johor' },
  { value: 'Sarawak', label: 'Sarawak' },
  { value: 'Sabah', label: 'Sabah' },
  { value: 'Perak', label: 'Perak' },
  { value: 'Penang', label: 'Penang' },
  { value: 'Kedah', label: 'Kedah' },
  { value: 'Pahang', label: 'Pahang' },
  { value: 'Negeri Sembilan', label: 'Negeri Sembilan' },
  { value: 'Kelantan', label: 'Kelantan' },
  { value: 'Terengganu', label: 'Terengganu' },
  { value: 'Melaka', label: 'Melaka' },
  { value: 'Putrajaya', label: 'Putrajaya' },
  { value: 'Perlis', label: 'Perlis' },
  { value: 'Labuan', label: 'Labuan' },
];

export { SHOP_NAME, BASE_URL, GUI_VERSION, STATE_LIST };
