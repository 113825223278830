/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';
import LocalStorageService from '../services/localstorageservice';

const baseUrl = '/merchant/v1/notification';
const localStorageService = LocalStorageService.getService();

const getNotifications = async (
  skip: number,
  take: number,
) => {
  try {
    const output = await axiosApiInstance.get(
      `${baseUrl}/notifications?skip=${skip}&take=${take}`
    );
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const getNotificationById = async (notificationId: number) => {
  try {
    const output = await axiosApiInstance.get(`${baseUrl}/notification?id=${notificationId}`);
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
};

const readAll = async () => {
  try {
    const output = await axiosApiInstance.put(`${baseUrl}/readall`);
    return output;
  } catch (e) {
    /* eslint-disable no-console */
    console.log(e);
    throw e;
  }
};

export { getNotifications, getNotificationById, readAll };
